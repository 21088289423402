// Typography
.h1 {
  @apply text-4xl font-extrabold leading-tight tracking-tighter;
}

.h2 {
  @apply text-3xl font-extrabold leading-tight tracking-tighter;
}

.h3 {
  @apply text-3xl font-bold leading-tight;
}

.h4 {
  @apply text-2xl font-bold leading-snug tracking-tight;
}

@screen md {
  .h1 {
    @apply text-5xl;
  }

  .h2 {
    @apply text-4xl;
  }
}

// Buttons
.btn,
.btn-sm {
  @apply font-medium inline-flex items-center justify-center border border-transparent rounded leading-snug transition duration-150 ease-in-out;
}

.btn {
  @apply px-8 py-3 shadow-lg;
}

.btn-sm {
  @apply px-4 py-2 shadow;
}
